import { useLoadContext } from "../contextLib/contextLib"
import { clientApi, getUsers, updateUser } from "../apiLib/apiLib"
import { Auth } from "@aws-amplify/auth"
import { Client, Team, Company, UserFromDb } from "../DatabaseObjects/DatabaseObjects"

declare global {
	var Intercom: any
	var rg4js: any
}

interface Attributes {
	email: string
	name: string
	jobTitle?: string
	phone?: string
	"custom:companyId": string
	"custom:company": string
	"custom:subdomain": string
	"custom:userrole": string
}

interface Response {
	username: string
	attributes: Attributes
	challengeParam?: {
		userAttributes: Attributes
	}
}

interface Session {
	idToken: {
		jwtToken: string
	}
}

export function setRaygunUserFromAttributes({ name, email }: Partial<Attributes>) {
	window?.rg4js?.("setUser", {
		identifier: email,
		isAnonymous: false,
		email,
		fullName: name,
	})
}

export function setIntercomUserFromAttributes({
	name,
	email,
	"custom:userrole": userRole,
	"custom:company": companyName,
	"custom:companyId": id,
}: Partial<Attributes>) {
	const isAdmin = userRole === "admin"
	window.Intercom?.("boot", {
		api_base: "https://api-iam.intercom.io",
		app_id:
			process.env.REACT_APP_IS_DEV === "true" || process.env.REACT_APP_IS_TEST === "true" ? "l3ib09kz" : "v4dc86p5",
		name,
		email,
		company: {
			id: id,
			name: companyName,
		},
		userRole: userRole,
		is_admin: isAdmin,
	})
}

/**
 *
 * @param userObj the response from a call to the database to grab user data
 * @param response  the response from a call to cognito or data stripped from a jwt
 * @param attributes the attributes from a sign up sequence, should be equivalent to the response.attributes
 * @returns user details for local use
 */
export function makeUser(userObj: UserFromDb | null, response: Response, attributes: Partial<Attributes>) {
	attributes = attributes && attributes.name ? attributes : response.attributes ?? {}

	if (attributes?.email == null || attributes?.email === "No Email") {
		// There should be no corresponding user in the database so use attributes directly to avoid unexpected behaviour
		return {
			isAuthenticated: true,
			name: attributes?.name,
			email: attributes?.email,
			id: response?.username,
			role: attributes?.["custom:userrole"],
			phone: attributes?.phone,
			jobTitle: attributes?.jobTitle,
			permissions: {},
			betaPermissions: {},
		}
	}

	return {
		isAuthenticated: true,
		name: userObj?.name ?? attributes.name,
		email: userObj?.miscData ? userObj?.miscData.split("#")[1] : attributes.email,
		id: userObj?.sk ? userObj?.sk.split("#")[1] : response.username,
		role: userObj?.userRole ?? attributes["custom:userrole"],
		phone: userObj?.phone ?? attributes.phone,
		jobTitle: userObj?.jobTitle ?? attributes.jobTitle,
		permissions: userObj?.permissions ?? {},
		betaPermissions: userObj?.betaPermissions ?? {},
	}
}

export function makeCompany(attributes: Attributes, team: Team, numberRoles: number, clientObj: Client): Company {
	const stats = clientObj.stats
	const font = clientObj.font
	const options = clientObj.options
	const branding = { font, ...clientObj.branding }
	const brandColors = clientObj.brandColors
	const logoName = clientObj?.logos?.current
	return {
		id: attributes?.["custom:companyId"],
		name: attributes?.["custom:company"] ?? clientObj.name,
		subdomain: attributes?.["custom:subdomain"] ?? clientObj.subdomain,
		logoName,
		team,
		stats,
		numberRoles,
		branding,
		brandColors,
		options,
	}
}

interface PostLoginProps {
	setUser: (user: any) => void
	setCompany: (company: any) => void
	response: Response
	unconfirmed?: boolean
	defaultTeam?: Team
	sessionOverride?: Session
}

export async function postLogin({
	setUser,
	setCompany,
	response,
	unconfirmed = false,
	defaultTeam = {},
	sessionOverride,
}: PostLoginProps) {
	const attributes = unconfirmed ? response?.challengeParam?.userAttributes : response?.attributes
	const [clientObj, userObj]: [Client, UserFromDb | null] = (await Promise.all([
		clientApi.get({
			clientId: attributes?.["custom:companyId"],
			sessionOverride,
		}),
		response.username == null
			? null
			: getUsers({ clientId: attributes?.["custom:companyId"], userId: response.username }).catch((e) => null),
	])) as [Client, UserFromDb]
	const numberRoles = parseInt((clientObj.miscData || " #0").split("#")[1])
	const company = makeCompany(attributes, clientObj.userlist ?? defaultTeam, numberRoles, clientObj)
	setCompany(company)
	const user = makeUser(userObj, response, attributes)
	setUser(user)
	if (response.username != null && user.email !== "No Email") {
		// user id given, register login
		console.log("register login")
		updateUser({ email: user.email, userId: user.id, name: user.name, clientId: company.id, userRole: user.role })
	}
	setRaygunUserFromAttributes({ ...attributes, ...userObj })
	setIntercomUserFromAttributes({ ...attributes, ...userObj })
}

interface LoginProps {
	responseOnly?: boolean
	setCompanyOverride?: (company: any) => void
	setUserOverride?: (user: any) => void
	unconfirmed?: boolean
}

// define overloading for different return types
export function useLogin(
	props: LoginProps & { responseOnly?: true }
): (props: { response: Response; sessionOverride?: Session }) => Promise<void>
export function useLogin(
	props: LoginProps & { responseOnly?: false }
): (props: {
	email: string
	password: string
	defaultTeam?: Team
	validationData: { [key: string]: string }
	sessionOverride?: Session
}) => Promise<void>
export function useLogin({ responseOnly = false, setCompanyOverride, setUserOverride, unconfirmed }: LoginProps) {
	const { setUser, setCompany } = useLoadContext()
	//Overriding is allowed in order to use the setters in the initial call before context is set
	const setUserFunc = setUserOverride || setUser
	const setCompanyFunc = setCompanyOverride || setCompany
	if (responseOnly) {
		return async ({ response, sessionOverride = undefined }) => {
			await postLogin({
				setUser: setUserFunc,
				setCompany: setCompanyFunc,
				response,
				unconfirmed,
				sessionOverride,
			})
		}
	} else {
		return async ({
			email,
			password,
			defaultTeam,
			validationData,
			sessionOverride,
		}: {
			email: string
			password: string
			defaultTeam: Team
			validationData: { [key: string]: string }
			sessionOverride: Session
		}) => {
			const response = await Auth.signIn(email, password, validationData)
			await postLogin({
				setUser: setUserFunc,
				setCompany: setCompanyFunc,
				response,
				unconfirmed,
				defaultTeam,
				sessionOverride,
			})
		}
	}
}

/**
 * Takes the current user's id and name, then places it in the same format as a user in the company.team object
 * @param {string} currUserId The current user's id
 * @param {string} currName The current user's name
 */
export function makeTeamFromCurrentUser(currUserId: string, currName: string, email: string): Team {
	return { [currUserId]: { name: currName, user_role: "admin", email } }
}
